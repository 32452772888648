import React from "react";

import async from "./components/Async";

// All pages that rely on 3rd party components (other than MUI) are
// loaded asynchronously, to keep the initial JS bundle to a minimum size

// Layouts
import AuthLayout from "./layouts/Auth";
import DashboardLayout from "./layouts/Dashboard";

// Guards
import AuthGuard from "./components/guards/AuthGuard";
import AuthGuardBS from "./components/guards/AuthGuardBS";

// Auth components
import SignIn from "./pages/auth/SignIn";
import ResetPassword from "./pages/auth/ResetPassword";
import ResetPasswordStep2 from "./pages/auth/ResetPasswordStep2"
import Page404 from "./pages/auth/Page404";
import Page500 from "./pages/auth/Page500";
import FindProvider from "./pages/auth/FindProvider"

// Protected routes
import GuestGuard from "./components/guards/GuestGuard";
import DefaultDashBoard from "./pages/dashboard";
import PendingInvoices from "./pages/minhas-faturas/pending";
import AllInvoices from "./pages/minhas-faturas/listagem";
import ContactPage from "./pages/Contato/contato";



// Dashboard components
const Default = async(() => import("./pages/dashboard"));

const routes = [
  {
    path: "/",
    element: <AuthLayout />,
    children: [
      {
        path: "",
        element: <GuestGuard>
          <SignIn />
          </GuestGuard>,
      },
      {
        path: "sign-in/",
        element: <SignIn />,
      },
      {
        path: "sign-in/:email",
        element: <SignIn />,
      },
      {
        path: "reset-password",
        element: <ResetPassword />,
      },
      {
        path: "reset-password/:id/:token",
        element: <ResetPasswordStep2 />,
      },
      {
        path: "find-provider/",
        element: <FindProvider />,
      },
      {
        path: "painel/login",
        element: <SignIn />,
      },
     
      {
        path: "404",
        element: <Page404 />,
      },
      {
        path: "500",
        element: <Page500 />,
      },
    ],
  },
  {
    path: "app",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: 
          <DefaultDashBoard />,
      }
    ],
    
  },
  {
    path: "app",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "contato",
        element: 
          <ContactPage />,
      }
    ],
    
  },
  ,
  {
    path: "app/faturas",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "listagem",
        element: 
          <AllInvoices />,
      },
      {
        path: "pendentes",
        element: <PendingInvoices />,
      }
    ],
    
  },
  ,
  



  
  {
    path: "*",
    element: <AuthLayout />,
    children: [
      {
        path: "*",
        element: <Page404 />,
      },
    ],
  },
];

export default routes;
