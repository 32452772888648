import React from "react";
import styled from "@emotion/styled";
import { Helmet } from "react-helmet-async";

import { Paper, Typography } from "@mui/material";

import { ReactComponent as Logo } from "../../vendor/logo.svg";
import ResetPasswordStep2Component from "../../components/auth/ResetPasswordStep2";
import useAuth from "../../hooks/useAuth";

const Brand = styled(Logo)`
  fill: ${(props) => props.theme.palette.primary.main};
  width: 64px;
  height: 64px;
  margin-bottom: 32px;
`;

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)};

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)};
  }
`;

function ResetPassword() {
  
  const { configuration } = useAuth();
  return (
    <React.Fragment>
     {
        configuration && 
        (
        configuration.brandmark?
          <center><img src={configuration.brandmark} width="250" height="150" /></center>
          :
          <center>
            <Typography component="h1" variant="h4" align="center" gutterBottom>
              {configuration.website_name}
            </Typography>
            </center>

          )

      }

      <Wrapper>
        <Helmet title="Recupere a sua Senha" />

        <Typography component="h1" variant="h4" align="center" gutterBottom>
          Recupere a sua Senha
        </Typography>
  
        <Typography component="h2" variant="body1" align="center">
          Informe o token recebido via e-mail para redefinir.
        </Typography>

        <ResetPasswordStep2Component />
      </Wrapper>
    </React.Fragment>
  );
}

export default ResetPassword;
