import React from "react";
import styled from "@emotion/styled";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";

import {
  CircularProgress,
  Divider as MuiDivider,
  Typography as MuiTypography,
} from "@mui/material";
import { Box, spacing } from "@mui/system";
import { green, red } from "@mui/material/colors";

import Grid from "@mui/system/Unstable_Grid";


import Stats from "./Stats";
import useAuth from "../../hooks/useAuth";
import DashboardTableInvoice from "../components/TableInvoice";

const Divider = styled(MuiDivider)(spacing);

const Typography = styled(MuiTypography)(spacing);



function DefaultDashBoard() {
  let { getApi,postApi,user} = useAuth();

  const { t } = useTranslation();
  const [loadQuery, setLoadQuery] = React.useState(true);

  const [query, setQuery] = React.useState({
    filterdate:3,
    period:3,
    include_mark_paid:true,
    groupby:'day'

  });
  const [loading, setLoading] = React.useState(false);
  const [balanceList, setBalanceList] = React.useState([]);

  
  React.useEffect(() => {
    LoadBalance()

  }, []);


  const LoadBalance = async () => {
    
    let response = await getApi("/balance",{});
    setBalanceList(response)
    setLoading(false)
  };

  return (
    <React.Fragment>
      <Helmet title="Dashboard" />
      <Grid justifyContent="space-between" container spacing={6}>
        <Grid item>
          <Typography variant="h3" gutterBottom>
            Bem vindo, {user&&user.firstname}
          </Typography>
          <Typography variant="subtitle1">
            {t("Acompanhe as suas faturas aqui.")}
            <span role="img" aria-label="Waving Hand Sign">
              👋
            </span>
          </Typography>
        </Grid>

        <Grid item>
        </Grid>
      </Grid>
      <Grid container spacing={6}>
     

        <Grid item xs={12} sm={12} md={6} lg={3} xl>
          
           {isNaN(parseFloat(balanceList.pending)) ?
            ( <Box sx={{ display: 'flex' }}>
                <CircularProgress />
              </Box>
            ):
            <Stats
              title="Faturas em aberto"
              amount={parseFloat(balanceList.pending).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}
              chip="Pendente"
              percentagecolor={green[500]}
            />
            }
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={3} xl>
          
            {isNaN(parseFloat(balanceList.overdue)) ?
             ( <Box sx={{ display: 'flex' }}>
                <CircularProgress />
              </Box>
             ):
             <Stats
            title="Faturas Vencidas"
            amount={parseFloat(balanceList.overdue).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}
            chip="Vencido"
            chipDanger={true}
            percentagecolor={red[100]}
          />
            }

        </Grid>
      </Grid>
      <Grid container spacing={6}>
        <Grid item xs={12} lg={12}>
          <DashboardTableInvoice status={0} limit={10} isComponent={false}/>
        </Grid>
      </Grid>

      <Divider my={6} />

     
    </React.Fragment>
  );
}

export default DefaultDashBoard;
