import { config } from "process";
import React from "react";
import { Navigate } from "react-router-dom";

import useAuth from "../../hooks/useAuth";

// For routes that can only be accessed by authenticated users
function AuthGuard({ children }) {
  const { isAuthenticated, isInitialized,isInitializedConfig } = useAuth();

  if (isInitialized && !isAuthenticated) {
    return <Navigate to="/sign-in" />;
  }
  
  if(isInitializedConfig || isInitialized){
    return <React.Fragment>{children}</React.Fragment>;
  }

}

export default AuthGuard;
