import React from "react";
import * as Yup from "yup";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Formik } from "formik";
import { Helmet } from "react-helmet-async";

import {
  Alert as MuiAlert,
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  CircularProgress,
  Divider as MuiDivider,
  Grid,
  Link,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Paper,
  TextField as MuiTextField,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import useAuth from "../../hooks/useAuth";
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import LanguageIcon from '@mui/icons-material/Language';
import FacebookIcon from '@mui/icons-material/Facebook';
import EmailIcon from '@mui/icons-material/Email';


const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Card = styled(MuiCard)(spacing);

const Alert = styled(MuiAlert)(spacing);

const TextField = styled(MuiTextField)(spacing);

const Button = styled(MuiButton)(spacing);

const timeOut = (time) => new Promise((res) => setTimeout(res, time));



const validationSchema = Yup.object().shape({
    firstname: Yup.string().required("Required"),
    lastname: Yup.string().required("Required"),
  email: Yup.string().email().required("Required"),
  subject: Yup.string()
    .min(6, "Deve ter no minimo 6 caracteres")
    .max(380)
    .required("Required"),
  msg: Yup.string()
  .min(10, "Deve ter no minimo 10 caracteres")
  .max(1000)
  .required("Required")
  ,
});

function BasicForm() {
    let { getApi,postApi,user} = useAuth();
    console.log(user)


  const handleSubmit = async (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    try {
        await postApi("/contact",{
            subject:values.subject,
            msg:values.msg
        })
        resetForm();
        setStatus({ sent: true });
        setSubmitting(false);
    } catch (error) {
      setStatus({ sent: false });
      setErrors({ submit: error.message });
      setSubmitting(false);
    }
  };

  
  return user?(
    <Formik
      initialValues={user}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        status,
      }) => (
        <Card mb={6}>
          <CardContent>
            <Typography variant="h6" gutterBottom>
              Envie uma mensagem
            </Typography>
            <Typography variant="body2" gutterBottom>
              Receberemos a sua mensagem e entraremos em contato rapidamente.
            </Typography>

            {status && status.sent && (
              <Alert severity="success" my={3}>
                 Sua mensagem foi enviada com sucesso, entraremos em contato em breve.
              </Alert>
            )}

            {isSubmitting ? (
              <Box display="flex" justifyContent="center" my={6}>
                <CircularProgress />
              </Box>
            ) : (
              <form onSubmit={handleSubmit}>
                <Grid container spacing={6}>
                  <Grid item md={6}>
                    <TextField
                      name="firstName"
                      label="First Name"
                      value={values.firstname}
                      disabled={true}
                      error={Boolean(touched.firstname && errors.firstname)}
                      fullWidth
                      helperText={touched.firstname && errors.firstname}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      variant="outlined"
                      my={2}
                    />
                  </Grid>
                  <Grid item md={6}>
                    <TextField
                      name="lastName"
                      label="Last Name"
                      disabled={true}
                      value={values.lastname}
                      error={Boolean(touched.lastname && errors.lastname)}
                      fullWidth
                      helperText={touched.lastname && errors.lastname}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      variant="outlined"
                      my={2}
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={6}>
                  <Grid item md={6}>
                    <TextField
                    name="email"
                    label="Email retorno"
                    value={values.email}
                    disabled={true}
                    error={Boolean(touched.email && errors.email)}
                    fullWidth
                    helperText={touched.email && errors.email}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="email"
                    variant="outlined"
                    my={2}
                    />
                
                    </Grid>
                    <Grid item md={6}>
                        <TextField
                            name="phone"
                            label="Telefone retorno"
                            value={values.phone}
                            disabled={true}
                            error={Boolean(touched.phone && errors.phone)}
                            fullWidth
                            helperText={touched.phone && errors.phone}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            type="text"
                            variant="outlined"
                            my={2}
                        />
                
                    </Grid>
                </Grid>


                <TextField
                  name="subject"
                  label="Assunto da  mensagem"
                  value={values.subject}
                  error={Boolean(touched.subject && errors.subject)}
                  fullWidth
                  helperText={touched.subject && errors.subject}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="text"
                  variant="outlined"
                  my={2}
                />

                <TextField
                  name="msg"
                  label="Sua mensagem"
                  value={values.msg}
                  error={Boolean(
                    touched.msg && errors.msg
                  )}
                  fullWidth
                  helperText={touched.msg && errors.msg}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="text"
                  variant="outlined"
                  multiline
                  rows={6}
                  maxRows={6}
                  my={2}
                />

                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  mt={3}
                >
                  Enviar Mensagem
                </Button>
              </form>
            )}
          </CardContent>
        </Card>
      )}
    </Formik>
  ):(
    <React.Fragment></React.Fragment>
  );
}

function ContactPage() {
    
  return (
    <React.Fragment>
      <Helmet title="Fale com a gente" />
      <Typography variant="h3" gutterBottom display="inline">
        Fale conosco
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/app">
          Dashboard
        </Link>
        <Link component={NavLink} to="/">
          Contato
        </Link>
        <Typography>Formulário</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container>
        
        <Grid item md={7} sm={12}>
        <BasicForm />
        </Grid>
        <Grid item md={3} sm={12} mt={15} ml={4}>
            <IconMenu />
        </Grid>


      </Grid>
    </React.Fragment>
  );
}


function IconMenu() {
    let { getApi,postApi,user, configuration} = useAuth();

    return (
      <Paper sx={{ width: 320, maxWidth: '100%' }}>
        <MenuList>

        {configuration.contacts.map((row) => (
         <React.Fragment>
            <MenuItem>
                <ListItemIcon>
                
                {
                     (    
                        (row.type==1 &&  <LanguageIcon  fontSize="small" />) || 
                        (row.type==2 &&  <EmailIcon  fontSize="small" />) || 
                        (row.type==3 &&  <InstagramIcon  fontSize="small" />)|| 
                        (row.type==4 &&  <FacebookIcon  fontSize="small" />) || 
                        (row.type==5 &&  <TwitterIcon  fontSize="small" />)
                     )
                }
                
              
                </ListItemIcon>
                <ListItemText>
                {
                     (    
                        (row.type==1&&"Site") || 
                        (row.type==2&&"E-mail") || 
                        (row.type==3&&"Instagram")|| 
                        (row.type==4&&"Facebook")|| 
                        (row.type==5&&"Twitter")
                     )
                }
                </ListItemText>
                <Typography variant="body2" color="text.secondary">
                <Link  href={
                (    (row.type==1&&"https://") || 
                    (row.type==2&&"mailto:") || 
                    (row.type==3&&"https://instagram.com/")|| 
                    (row.type==4&&"https://facebook.com/")|| 
                    (row.type==5&&"https://twitter.com/")
                )



                    +row.value} >{row.value}</Link>
                </Typography>
            </MenuItem>
            <Divider />
         </React.Fragment>
           ))}
          
        </MenuList>
      </Paper>
    );
  }



export default ContactPage;
