import React from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";

import { green } from "@mui/material/colors";

import { Box, Chip, Drawer as MuiDrawer, ListItemButton, Typography } from "@mui/material";

import { ReactComponent as Logo } from "../../vendor/logo.svg";
import Footer from "./SidebarFooter";
import SidebarNav from "./SidebarNav";
import useAuth from "../../hooks/useAuth";

const Drawer = styled(MuiDrawer)`
  border-right: 0;

  > div {
    border-right: 0;
  }
`;

const Brand = styled(ListItemButton)`
  font-size: ${(props) => props.theme.typography.h5.fontSize};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
  color: ${(props) => props.theme.sidebar.header.color};
  background-color: ${(props) => props.theme.sidebar.header.background};
  font-family: ${(props) => props.theme.typography.fontFamily};
  min-height: 56px;
  padding-left: ${(props) => props.theme.spacing(6)};
  padding-right: ${(props) => props.theme.spacing(6)};
  justify-content: center;
  cursor: pointer;
  flex-grow: 0;
  padding-top:20px;

  ${(props) => props.theme.breakpoints.up("sm")} {
    min-height: 64px;
  }

  &:hover {
    background-color: ${(props) => props.theme.sidebar.header.background};
  }
`;



const Sidebar = ({ items, showFooter = true, ...rest }) => {
  const { configuration,isInitializedConfig,isInitialized } = useAuth();


  return (
    <Drawer variant="permanent" {...rest}>
       {(isInitializedConfig ) && 
<React.Fragment>
      <Brand component={NavLink} to="/">
        <Box ml={1}>
          
      {
     
        configuration.brandmark?
          <img src={configuration.brandmark} width="220" height={"90"} />
          :
          <Typography component="h1" variant="h4" align="center" gutterBottom color={'black'} >
            {configuration.website_name}
          </Typography>


      }
        </Box> 
      </Brand>
      <SidebarNav items={items} />
    
      {!!showFooter && <Footer configuration={configuration} />}

      
      </React.Fragment>
    }
    </Drawer>
  );  
};

export default Sidebar;
