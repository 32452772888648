import { createContext, useEffect, useReducer } from "react";

import axios from "../utils/axios";
import { setSession,setBusinessAccount } from "../utils/jwt";
import Echo from 'laravel-echo';
import Pusher from 'pusher-js';

// Note: If you're trying to connect JWT to your own backend, don't forget
// to remove the Axios mocks in the `/src/index.js` file.

const INITIALIZE = "INITIALIZE";
const INITIALIZEBS = "INITIALIZEBS";
const INITIALIZECONFIG = "INITIALIZECONFIG";
const SIGN_IN = "SIGN_IN";
const SIGN_OUT = "SIGN_OUT";
const SIGN_UP = "SIGN_UP";
const ShowModalInit = "ShowModalInit";
const Session_BusinessAccountId="@CacheBank_BusinessAccount"

const initialState = {
  isAuthenticated: false,
  isInitialized: false,

  isAuthenticatedBS: false,
  isInitializedBS: false,
  isInitializedConfig: false,
  user: {
    id: null,
    fullname: null,
    firstname: null,
    email: null,
    identifier: null,
    phone: null,
    lastname:null
},
  businessAccount:[],

  isBSAccount:false,

  configuration:{
    styled:{
      style_login_area_background_color:null,
      style_client_header_background_color:null
    },
    contacts:[],
    auth_identifier:null,
    auth_email:null,

    brandmark:null,
  },

  
  showModal:null,

};


const URLBASE=process.env.REACT_APP_URL_API;

const JWTReducer = (state, action) => {
  switch (action.type) {

    case INITIALIZECONFIG:
      return {
        configuration: action.payload.configuration,
        isInitializedConfig:true
      };

    case INITIALIZE:
      return {
        ...state,
        isAuthenticated: action.payload.isAuthenticated,
        isInitialized: true,
        user: action.payload.user
      };
    case INITIALIZEBS:
        return {
          ...state,
          isAuthenticatedBS:action.payload.isAuthenticatedBS,
          isInitializedBS:true,
          isBSAccount:action.payload.isBSAccount
    };
  
    case SIGN_IN:
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
      };
    case SIGN_OUT:
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };

    case SIGN_UP:
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
      };
    case ShowModalInit:
        return {
          ...state,
          showModal:action.payload.showModal
    };
    default:
      return {
        ...state
      };
  }
};

const AuthContext = createContext(null);

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(JWTReducer, initialState);
  useEffect(() => {
    const initialize = async () => {
      checkSiteOwner();
      checkAuth();
    };

    initialize();
  }, []);

  
  const checkSiteOwner = async()=>{
    let location=window.location.href;
    try {
      if(location.includes("/find-provider")){
      return ;
     }
    
      const response = await axios.get(URLBASE+"/configuration");
    
      if(response){
       
       let jsonResult=response.data;
        
        if(jsonResult.success==false){
       
          console.log(location)
          if(location.includes("https://minhasfaturas.com") || location.includes("https://vitrine.center") ||  location.includes("http://localhost:3002")){
            window.location.href="/find-provider";
            return ;
          }
  
     //     window.location.href="/404";



          return ;
        }
      }else{
       // window.location.href="/404";
        return ;
      }
      

      let configuration=response.data.configuration;
      dispatch({
        type: INITIALIZECONFIG,
        payload: {
          configuration: configuration
        },
      });


      const accessToken = window.localStorage.setItem("website_name", configuration.website_name);

    }catch(ex){
     
     //   window.location.href="/404";
        return ;
    }
  }




  const checkAuth = async()=>{
    try {
      const accessToken = window.localStorage.getItem("accessToken");

      if (accessToken) {
        setSession(accessToken);
        let userValid=false;
        const response = await axios.get(URLBASE+"/user");
        if(response){
          userValid=response.data.user?true:false
        }
        
        const { user } = response.data;


        dispatch({
          type: INITIALIZE,
          payload: {
            isAuthenticated: userValid,
            user:user
          },
        });
      } else {
        dispatch({
          type: INITIALIZE,
          payload: {
            isAuthenticated: false,
            isAuthenticatedBS:false,
            user: null,
          },
        });
      }
    } catch (err) {
      console.error(err);
      dispatch({
        type: INITIALIZE,
        payload: {
          isAuthenticated: false,
          user: null,
        },
      });
    }
  }

  const signIn = async (username, password) => {
    const response = await axios.post(URLBASE+"/user/auth", {
      username:username,
      password,
    });
    if(response){
      const { token } = response.data;


  
  
      setSession(token);
    }else{
      return response;
    }

    
   
  };

  const findProvider = async (username) => {
    const response = await axios.post(URLBASE+"/find-provider", {
      username:username
    });
    return response.data;

    
   
  };


  const ShowModalFunc = async (modal) => {
    dispatch({
      type: ShowModalInit,
      payload: {
        showModal:modal,
      },
    });
  };


  const signOut = async () => {
    const response = await axios.post(URLBASE+"/user/auth/logout",{});
    if(response){
      const { success} = response.data;

      return success;
    }else{
      return response;
    }

    

    setSession(null);
    dispatch({ type: SIGN_OUT });
  };

  const signUp = async (fullname, email, password, phone) => {
    let responsedata=null;

    const submitAxios = await axios.post(URLBASE+"/signup", {
      fullname,
      email,
      password,
      phone,
    }).catch(error => {
      if (error) {
        console.log(error)
        responsedata=error;
      }
    });
    if(submitAxios){
      responsedata=submitAxios.data;

      const { api_token, user } = submitAxios.data;

      window.localStorage.setItem("accessToken", api_token);
    }
   
    return responsedata;
  };

  const signUpStep2 = async (url,data) => {
    let responsedata=null;

    const submitAxios = await axios.post(URLBASE+url, data ).catch(error => {
      if (error) {
        console.log(error)
        responsedata=error;
      }
    });

    if(submitAxios){
      responsedata=submitAxios.data;

      const { general_business_account_id } = submitAxios.data;

      window.localStorage.setItem(Session_BusinessAccountId, general_business_account_id);
     
    }
   
    return responsedata;
  };

  


  const getApi = async (url,data) => {
    let responsedata=null;

    const accessToken = window.localStorage.getItem("accessToken");
    const CacheBank_BusinessAccount = window.localStorage.getItem(Session_BusinessAccountId);

    if (accessToken) {
      setSession(accessToken);
    }
    if (CacheBank_BusinessAccount) {
      setBusinessAccount(CacheBank_BusinessAccount);
    }

    const submitAxios = await axios.get(URLBASE+url, data ).catch(error => {
      if (error) {
        console.log(error)
        responsedata=error;
      }
    });
    if(submitAxios){
      responsedata=submitAxios.data;
    }
   
    return responsedata;
  };


  const deleteApi = async (url,data) => {
    let responsedata=null;
    console.log(data)

    const accessToken = window.localStorage.getItem("accessToken");
    const CacheBank_BusinessAccount = window.localStorage.getItem(Session_BusinessAccountId);

    if (accessToken) {
      setSession(accessToken);
    }
    if (CacheBank_BusinessAccount) {
      setBusinessAccount(CacheBank_BusinessAccount);
    }


    const submitAxios = await axios.delete(URLBASE+url, data ).catch(error => {
      if (error) {
        console.log(error)
        responsedata=error;
      }
    });
    if(submitAxios){
      responsedata=submitAxios.data;
    }
   
    return responsedata;
  };


  const postApi = async (url,data) => {
    let responsedata=null;
    console.log(data)

    const accessToken = window.localStorage.getItem("accessToken");
    const CacheBank_BusinessAccount = window.localStorage.getItem(Session_BusinessAccountId);

    if (accessToken) {
      setSession(accessToken);
    }
    if (CacheBank_BusinessAccount) {
      setBusinessAccount(CacheBank_BusinessAccount);
    }


    const submitAxios = await axios.post(URLBASE+url, data ).catch(error => {
      if (error) {
        console.log(error)
        responsedata=error;
      }
    });
    if(submitAxios){
      responsedata=submitAxios.data;
    }
   
    return responsedata;
  };



  




  const resetPassword = async (email) => {
    const response = await axios.post(URLBASE+"/user/auth/reset/generatecode", {
      username:email
    });
    if(response){
      const { success} = response.data;

      return success;
    }else{
      return response;
    }

    
   
  };

  
  const resetPasswordStep2 = async ( store_users_id,password,token) => {
    const response = await axios.post(URLBASE+"/user/auth/reset/pass", {
      store_users_id,password,token
    });

    if(response){
      const { success } = response.data;


      if (success) {
      }
  
  
    }else{
      return response;
    }
    
   
  };



  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: "jwt",
        signIn,
        findProvider,
        signOut,
        signUp,
        resetPassword,
        resetPasswordStep2,
        getApi,
        signUpStep2,
        postApi,
        deleteApi,
        ShowModalFunc
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
