import React from "react";
import styled from "@emotion/styled";
import { Helmet } from "react-helmet-async";

import { Avatar, Button, Card, CardActions, CardContent, CardMedia, Dialog, DialogContent, DialogTitle, Paper, TextField, Typography,View } from "@mui/material";

import FindProviderComponent from "../../components/auth/FindProvider";
import useAuth from "../../hooks/useAuth";
import { Box, Container } from "@mui/system";
import QrCode2Icon from '@mui/icons-material/QrCode2';
import QRCode from "react-qr-code";
import appStoreIcon from "../../assets/appstoreicon.png"
import playStoreIcon from "../../assets/playstoreicon.png"
import { Link } from "react-router-dom";
import Logo from "../../assets/logo.png"
const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)};

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(8)};
    padding-top: ${(props) => props.theme.spacing(0)};
  }
`;

const BigAvatar = styled(Avatar)`
  width: 92px;
  height: 92px;
  text-align: center;
  margin: 0 auto ${(props) => props.theme.spacing(5)};
`;

function SignIn() {
  
const { configuration } = useAuth();
const [openOneClickNotification, setOpenOneClickNotification] = React.useState(false);

const closeModalNotification=()=> {
  setOpenOneClickNotification(false);

 
}

const  copyToClipBoard=async (value)=> {
  

  await navigator.clipboard.writeText(value);


  // Alert the copied text
  alert("Código copiado, agora é só colar no App ;)");
}
  return (
    <React.Fragment>
     <Container sx={{marginTop:window.isNativeApp?10:20}}>
     <center><img src={Logo} width="180" height="180" /></center>
      
      <Wrapper>
        {configuration &&
         <Helmet title={"Login"} />
        }


        <FindProviderComponent />
        
      
      </Wrapper>
      </Container>
     


    </React.Fragment>
  );
}

export default SignIn;
