import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "@emotion/styled";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";

import {
  Alert as MuiAlert,
  Checkbox,
  FormControlLabel,
  Button,
  TextField as MuiTextField,
} from "@mui/material";
import { spacing } from "@mui/system";

import useAuth from "../../hooks/useAuth";

const Alert = styled(MuiAlert)(spacing);

const TextField = styled(MuiTextField)(spacing);

function SignIn() {
  const navigate = useNavigate();
  const { findProvider,configuration } = useAuth();

  let { resetedPassword } = useParams();


  return (
    <Formik
      initialValues={{
        username: "",
        password: "",
        submit: false,
      }}
      validationSchema={Yup.object().shape({
        username: Yup.string()
          .max(255)
          .required("O login deve ser preenchido")
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
         let response= await findProvider(values.username);
         if(response){
          let config=response[0]["configuration"];
          window.location.replace("https://"+config["domain"]["fulldomain"]+"/sign-in/"+values.username);
         }
         
          
         
        } catch (error) {
          console.log(error);
          const message = error.message["error"] || "Um erro ocorreu. Por favor, tente novamente.";

          setStatus({ success: false });
          setErrors({ submit: message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form noValidate onSubmit={handleSubmit}>
         
         <Alert mt={3} mb={3} severity="info">
                  <React.Fragment>
                    Para começar, informe seu E-mail ou CPF
                  </React.Fragment>
                </Alert>
           
          {errors.submit && (
            <Alert mt={2} mb={3} severity="warning">
              {errors.submit}
            </Alert>
          )}
          <TextField
            type="text"
            name="username"
            label= {"E-mail ou CPF"}
            value={values.username}
            error={Boolean(touched.username && errors.username)}
            fullWidth
            helperText={touched.username && errors.username}
            onBlur={handleBlur}
            onChange={handleChange}
            my={2}
          />
         { /*<FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Lembrar"
          />*/}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={isSubmitting}
          >
            Verificar
          </Button>
          {/* <Button
            component={Link}
            to="/reset-password"
            fullWidth
            color="primary"
          >
            Esqueci a senha
          </Button> */}
        </form>
      )}
    </Formik>
  );
}

export default SignIn;
